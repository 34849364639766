<template>
    <div class="white-bg">
		<div class="main-wrap">
			<div class="container">
				<!-- Ministries content -->
				<div class="col-md-16">
					<div class="ministries-head">
						<h5>Departement</h5>
						
					</div>
					<!-- <div class="ministries-list" v-for="(dp,index) in dpt" :key="index">
						<div class="row">
							<div class="col-md-4"><i :class="dp.logo" class="fa-5x"></i></div>
							<div class="col-md-12">
								<ul class="hb-social">
									<li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
									<li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
									<li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
								</ul>
								<h4><a href="#">{{dp.nom}}</a></h4>
								<div v-html="dp.description.substring(3,420)+'...'"></div>
							</div>
						</div>
					</div> --> 
					<div class="ministries-list">
						<div class="row">
							<div class="col-md-4"><img src="demo/ministries/1.jpg" class="img-responsive" alt=""/></div>
							<div class="col-md-12">
								<ul class="hb-social">
									<li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
									<li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
									<li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
								</ul>
								<h4><a href="#">PROJET ET FINANCE</a></h4>
								<p>Le département Projet et Insertion Socioprofessionnelle est l’organe de la JNEPCI qui est chargé de suivre tous les projets de la JNEPCI jusqu’à leur réalisation, d’en initier et de veiller d’aider à l’insertion socio professionnelle de ses membres</p>
							</div>
						</div>
					</div>
					<div class="ministries-list">
						<div class="row">
							<div class="col-md-4"><img src="demo/ministries/2.jpg" class="img-responsive" alt=""/></div>
							<div class="col-md-12">
								<ul class="hb-social">
									<li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
									<li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
									<li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
								</ul>
								<h4><a href="#">Intercession</a></h4>
								<p>Nous rendons toute la gloire à notre Seigneur JESUS-CHRIST, qui a montré de la plus belle des manières, à ses disciples en premier et à nous chrétiens composant le corps de Christ d’aujourd’hui par la suite, que tout ministère repose sur la prière (MARC 1 :35-39).</p>
							</div>
						</div>
					</div>
					<div class="ministries-list">
						<div class="row">
							<div class="col-md-4"><img src="demo/ministries/3.jpg" class="img-responsive" alt=""/></div>
							<div class="col-md-12">
								<ul class="hb-social">
									<li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
									<li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
									<li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
								</ul>
								<h4><a href="#">Musique</a></h4>
								<p>Le département de louange est l’organe qui se charge de coordonner tout ce qui concerne le volet musical au sein de la JNEPCI</p>
							</div>
						</div>
					</div>
					<div class="ministries-list">
						<div class="row">
							<div class="col-md-4"><img src="demo/ministries/4.jpg" class="img-responsive" alt=""/></div>
							<div class="col-md-12">
								<ul class="hb-social">
									<li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
									<li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
									<li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
								</ul>
								<h4><a href="#">Evangelisation</a></h4>
								<p>Le département Evangélisation est la structure de la JNEPCI qui est chargée d’administrer tous les programmes d’évangélisation, tous les programmes permettant de gagner des âmes</p>
							</div>
						</div>
					</div>
					<div class="ministries-list">
						<div class="row">
							<div class="col-md-4"><img src="demo/ministries/5.jpg" class="img-responsive" alt=""/></div>
							<div class="col-md-12">
								<ul class="hb-social">
									<li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
									<li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
									<li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
								</ul>
								<h4><a href="#">Communication</a></h4>
								<p>Le département communication est l’organe de la JNEPCI qui a pour rôle de coordonner et rendre possible et fluide toute forme de communication au sein de la JNEPCI</p>
							</div>
						</div>
					</div>
					<div class="ministries-list lasts">
						<div class="row">
							<div class="col-md-4"><img src="demo/ministries/6.jpg" class="img-responsive" alt=""/></div>
							<div class="col-md-12">
								<ul class="hb-social">
									<li class="hb-fb"><a href="#"><i class="fa fa-facebook"></i></a></li>
									<li class="hb-tw"><a href="#"><i class="fa fa-twitter"></i></a></li>
									<li class="hb-plus"><a href="#"><i class="fa fa-plus"></i></a></li>
								</ul>
								<h4><a href="#">Service d'ordre</a></h4>
								<p>Le département service d’ordre et sécurité est l’organe qui assure l’ordre et la sécurité au sein de la JNEPCI</p>
							</div>
						</div>
					</div>
				</div>
				<!-- Ministries content -->
			</div>
		</div>
	</div>
</template>
<script>
import axios from 'axios';
import { JNEPCI_URL } from '@/config'
export default {
    data: function () {
        return {
            dpt:null
        }

    },
    mounted:function(){
        this.getDepartement()
    },
    methods:{
        getDepartement: function() {
            let app = this;
            app.isLoading = true;
            axios.get(JNEPCI_URL+'departements')
            .then((response) => {
				console.log(response)
                app.dpt = response.data
                this.isLoading = false;
            })
            .catch((   ) => {
                this.isLoading = false;
            });
        },
    }
}
</script>