import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Donation from '../views/donate.vue'
import Apropos from '../views/apropos.vue'
import Departement from '../views/departement.vue'
import Actualites from '../views/actualite.vue'
import ActualitesDetails from '../views/actualiteDetails.vue'
import Contact from '../views/contact.vue'
import Enseignement from '../views/enseignement.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/a-propos',
        name: 'Apropos',
        component: Apropos
    },
    {
        path: '/donation',
        name: 'Donation',
        component: Donation
    },
    {
        path: '/departements',
        name: 'Departement',
        component: Departement
    },
    {
        path: '/enseignements',
        name: 'Enseignement',
        component: Enseignement
    },
    {
        path: '/actualites',
        name: 'Actualites',
        component: Actualites
    },
    {
        path: '/actualite-details/:id',
        name: 'ActualitesDetails',
        component: ActualitesDetails
    },
    {
        path: '/contact',
        name: 'Contact',
        component: Contact
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

export default router