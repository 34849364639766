<template>
    <div class="white-bg">
        <div class="main-wrap">
            <div class="container">
                <div class="col-md-16">
                    <form @submit.prevent="Donation">
                        <ul class="shop-accordion">
                            <li class="current">
                                <a href="#1" onclick="return false;">
                                    <h3>1. Addresse</h3>
                                </a>
                                <div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input type="text" v-model="donate.prenoms" placeholder="Prénoms">
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" v-model="donate.nom" placeholder="Nom">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-16">
                                            <input type="text" v-model="donate.entreprise" placeholder="Nom de l'entreprise (facultatif)">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-11">
                                            <input type="text" v-model="donate.pays" placeholder="Pays">
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input type="text" v-model="donate.ville" placeholder="Ville">
                                        </div>
                                    </div>
                                    
                                    <div class="row">
                                        <div class="col-md-8">
                                            <input type="text" v-model="donate.email" placeholder="Email">
                                        </div>
                                        <div class="col-md-8">
                                            <input type="text" v-model="donate.contact" placeholder="Contact">
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="#2" onclick="return false;">
                                    <h3>2. Modes de transaction</h3>
                                </a>
                                <div>
                                    <div class="row payment-info">
                                        <div class="col-md-12 no-padding">
                                            <ul>
                                                
                                                <li :style=" donate.payment == 'visa'  ?'border : 2px solid #ffb400;':'2px solid #cbc6ac'" @click="donate.payment = 'visa'">
                                                    <img src="demo/payment/2.png" alt=""/>
                                                </li>
                                                <li :style=" donate.payment == 'wave'  ?'border : 2px solid #ffb400;':'2px solid #cbc6ac'" @click="donate.payment = 'wave'">
                                                    <img src="demo/payment/7.png" alt=""/>
                                                </li>
                                                <li :style=" donate.payment == 'mobile money'  ?'border : 2px solid #ffb400;':'2px solid #cbc6ac'" @click="donate.payment = 'mobile money'">
                                                    <img src="demo/payment/8.png" alt=""/>
                                                </li>
                                                <li :style=" donate.payment == 'Western union'  ?'border : 2px solid #ffb400;':'2px solid #cbc6ac'" @click="donate.payment = 'Western union'">
                                                    <img src="demo/payment/6.png" alt=""/>
                                                </li>
                                                <li :style=" donate.payment == 'money gram'  ?'border : 2px solid #ffb400;':'2px solid #cbc6ac'" @click="donate.payment = 'money gram'">
                                                    <img src="demo/payment/9.png" alt=""/>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </li>
                            <li>
                                <a href="#3" onclick="return false;">
                                    <h3>3. Commentaire</h3>
                                </a>
                                <div class="order-form">
                                    <div class="row order-comment">
                                        <div class="col-md-16">
                                            <textarea rows="6" v-model="donate.commentaire" placeholder="Votre commentaire ici"></textarea>
                                        </div>
                                    </div>
                                    <div class="row order-total-details">
                                        <div class="col-md-11">
                                        </div>
                                        <Preloader v-if="isLoading" color="red" scale="0.6" />
                                        <div v-else class="col-md-5">
                                            <button type="submit" class="c-btn">Envoyer</button>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import axios from 'axios';
import { JNEPCI_URL,JNEPCI } from '@/config'
import Preloader from '@/components/preloader.vue'
export default {
    components: {
        Preloader,
    },
    data: function () {
        return {
            payment:0,
            donate:{
                payment:''
            },
            isLoading:false
        }

    },

    methods:{
            Donation: function() {
                let app = this;
                app.isLoading = true;
                axios.post(JNEPCI_URL+'donate',this.donate)
                .then((response) => {
                    console.log(response)
                    app.teach = response.data
                    this.isLoading = false;
                })
                .catch((   ) => {
                    this.isLoading = false;
                });
            },
    }
}

</script>
